import {action, computed, observable} from "mobx";
import {persist} from "mobx-persist";

export default class AppStore {

  @persist
  @observable
  token?: string;

  @computed
  get isLoggedIn(): boolean {
    return !!this.token;
  }

  @action
  setToken = async (token: string) => {
    this.token = token
  };

  @action
  deleteToken = async () => {
    this.token = undefined;
  };
}