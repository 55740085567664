import {FormInstance} from "antd/es/form";
import {inject, observer} from "mobx-react";
import React, {ReactNode, RefObject} from "react";
import {ContactRequest} from "../../core/interfaces/requests/contact.request";
import ContactService from "../../core/services/contact.service";
import AppStore from "../../Store";
import "./home.scss";
import Logo from "../../assets/images/logo.png";
import {Menu, Button, Drawer, Input, Form, notification} from 'antd';
import { MenuOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import Header from "../../assets/images/header/header.png";
import About from "../../assets/images/about/about.png";
import WhatWeDo1 from "../../assets/images/content/what-we-do-1.png";
import WhatWeDo2 from "../../assets/images/content/what-we-do-2.png";
import WhatWeDo3 from "../../assets/images/content/what-we-do-3.png";
import Facebook from "../../assets/images/footer/facebook.svg";
import Twitter from "../../assets/images/footer/twitter.svg";
import Linkedin from "../../assets/images/footer/linkedin.svg";
const Catalogue = require('../../assets/files/Catalogue.pdf');

enum MenuItem {
  ABOUT,
  TREATMENT,
  PRODUCTS,
  CONTACT,
}

@inject("appStore")
@observer
export default class HomePage extends React.Component<{
  appStore: AppStore, history: any
}> {

  public static url: string = '/';
  private aboutUsRef = React.createRef<HTMLHeadingElement>();
  private productsRef = React.createRef<HTMLHeadingElement>();
  private treatmentsRef = React.createRef<HTMLHeadingElement>();
  private contactUsRef = React.createRef<HTMLHeadingElement>();
  private formRef = React.createRef<FormInstance>();
  private contactService: ContactService = new ContactService();

  state = {
    current: 'mail',
    visible: false,
    setVisible: false
  };

  handleClick = (e:any)=> {
    this.setState({ current: e.key });
  };

  showDrawer = () => {
    this.setState({
      visible: true
    })
  };
  onClose = () => {
    this.setState({
      visible: false
    })
  };

  private  handleMenuItemClick = (item: MenuItem)=> {
    let ref = React.createRef<HTMLHeadingElement>();
    switch (item) {
      case MenuItem.ABOUT:
        ref = this.aboutUsRef;
        break;
      case MenuItem.PRODUCTS:
        ref = this.productsRef;
        break;
      case MenuItem.TREATMENT:
        ref = this.treatmentsRef;
        break;
      case MenuItem.CONTACT:
        ref = this.contactUsRef;
        break;
    }
    this.scrollToRef(ref);
  };

  private scrollToRef = (ref: RefObject<any>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start'})
  }

  private handleFormFinish = async (contactRequest: ContactRequest): Promise<void> => {
    // await this.contactService.sendMail(contactRequest)
    await this.contactService.sendMailForm(contactRequest)
      .then(() => {
        notification.success({
          message: "Message has been successfully send",
          placement: "topRight",
        });
        this.formRef.current?.resetFields();
      })
      .catch(() => {
        notification.error({
          message: "Something went wrong!",
          placement: "topRight",
        })
      });
  }
  
  render(): ReactNode {
    const { TextArea } = Input;
    return (
      <div className="container-fluid" id="home">
        <div className="row" id="header">
          <div className="col-6 col-lg-4 col-sm-4 pt-3">
            <img src={Logo} alt="" id="logo"/>
          </div>
          <div className="col-6 col-lg-8 col-sm-8 pt-3" id="header-menu">
            <Menu id="menu" onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
              <Menu.Item key="about">
                <a href="javascript:void(0)" onClick={e => {
                  e.preventDefault();
                  this.handleMenuItemClick(MenuItem.ABOUT);
                }}>
                  About Us
                </a>
              </Menu.Item>
              <Menu.Item key="treatments">
                <a href="javascript:void(0)" onClick={e => {
                  e.preventDefault();
                  this.handleMenuItemClick(MenuItem.TREATMENT);
                }}>
                  Treatments & Services
                </a>
              </Menu.Item>
              <Menu.Item key="products">
                <a href="javascript:void(0)" onClick={e => {
                  e.preventDefault();
                  this.handleMenuItemClick(MenuItem.PRODUCTS);
                }}>
                  Products
                </a>
              </Menu.Item>
              <Menu.Item key="contact">
                <a href="javascript:void(0)" onClick={e => {
                  e.preventDefault();
                  this.handleMenuItemClick(MenuItem.CONTACT);
                }}>
                  Contact Us
                </a>
              </Menu.Item>
            </Menu>
            <Button id="drawer-button" onClick={this.showDrawer}>
              <MenuOutlined />
            </Button>
            <Drawer
              className="drawer"
              placement="right"
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <a href="javascript:void(0)" className="menu-item"
                onClick={e => {
                  e.preventDefault();
                  this.handleMenuItemClick(MenuItem.ABOUT);
                }}>
                About Us
              </a>
              <a href="javascript:void(0)" className="menu-item"
                onClick={e => {
                  e.preventDefault();
                  this.handleMenuItemClick(MenuItem.TREATMENT);
                }}>
                Treatments & Services
              </a>
              <a href={Catalogue} target='_blank' rel="noopener noreferrer" className="menu-item">
                Products
              </a>
              <a href="javascript:void(0)" className="menu-item"
                onClick={e => {
                  e.preventDefault();
                  this.handleMenuItemClick(MenuItem.CONTACT);
                }}>
                Contact Us
              </a>
            </Drawer>
          </div>
        </div>
        <div className="row" id="header-image">
          <div className="col-12 p-0 pt-3">
            <img className="img-fluid" style={{width:"100%"}} src={Header} alt=""/>
          </div>
        </div>
        <div className="row" id="about">
          <div className="col-12 col-lg-6 col-sm-12" id="about-image-content">
            <img id="about-image" src={About} alt=""/>
          </div>
          <div className="col-12 col-lg-6 col-sm-12 pl-4 mt-5" id="about-content">
              <h1 ref={this.aboutUsRef} className="title"><b>About Us</b></h1>
              <p id="content" style={{fontSize: 17}}>Founded in 1990 by Çimen Berik, Jade Herbal is a forward-thinking herbal products for healthy lifestyles.<br/><br/>
              <div ref={this.productsRef}/>
                  We begin with herbal waters and oil, following cosmetics, lately food supplements.<br/><br/>

                  Now, its over 400 products.
              </p>
              <Button id="see-all-products" className="mt-3">
                <a href={Catalogue} target='_blank' rel="noopener noreferrer">
                  See All Products
                </a>
              </Button>
          </div>
        </div>
        <div className="row mt-5" id="what-we-do">
          <div className="col-12" id="what-we-do-content">
            <h1 ref={this.treatmentsRef} className="title"><b>What We Do</b></h1>
            <div id="content-image">
              <div className="we-do-images-content">
                <img className="we-do-image" src={WhatWeDo1} alt=""/> 
                <h4 className="we-do-title" style={{marginTop: 32}}><b>Natural Health</b></h4>
                <p className="we-do-content">Herbal oils, waters, essences and food supplements</p>
              </div>
              <div className="we-do-images-content">
                <img className="we-do-image"src={WhatWeDo3} alt=""/> 
                <h4 className="we-do-title" style={{marginTop: 40}}><b>Natural Beauty</b></h4>
                <p className="we-do-content">Herbal Cosmetics</p>
              </div>
              <div className="we-do-images-content">
                <img className="we-do-image" src={WhatWeDo2} alt=""/> 
                <h4 className="we-do-title" style={{marginTop: 33}}><b>Balance Your Energy</b></h4>
                <p className="we-do-content">Herbal Food Supplements</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5" id="contact">
          <div className="col-12">
            <h1 ref={this.contactUsRef} className="title"><b>Contact Us</b></h1>
            <div id="contact-content">
              <PhoneOutlined className="contact-icon mr-lg-2 mr-md-2 mr-sm-0"/>
              <p className="mr-lg-4 mr-md-4 mr-sm-0">+90 0532 493 39 93</p>
              <MailOutlined className="contact-icon ml-lg-3 ml-md-4 ml-sm-0 mr-lg-2 mr-md-2 mr-sm-0"/>
              <p>info@jadeherbal.com</p>
            </div>
            <Form
              ref={this.formRef}
              onFinish={(values:any) => this.handleFormFinish(values)}
            >
                <div id="contact-form">
                  <div id="first-contact-form">
                    <Form.Item
                      className="d-inline-flex"
                      name="name"
                      rules={[{ required: true, message: 'Please enter your name!' }]}>
                      <Input className="contact-input mr-0 mr-lg-4 mr-md-4 pl-3 " placeholder="Name*"/>
                    </Form.Item>
                    <Form.Item
                      className="d-inline-flex"
                      name="surname"
                      rules={[{ required: true, message: 'Please enter your surname!' }]}>
                      <Input className="contact-input pl-3" placeholder="Surname*"/>
                    </Form.Item>
                  </div>
                  <Form.Item name="subject" className="subject-message-content">
                    <Input className=" full-contact-input pl-3" placeholder="Subject" style={{height: 50}}/>
                  </Form.Item>
                  <Form.Item name="message" className="subject-message-content">
                    <TextArea className="full-contact-input p-3" rows={6} placeholder="Your Message"/>
                  </Form.Item>
                  <Button className="mt-3" id="submit" htmlType="submit">Submit</Button>
              </div>
             </Form>
          </div>
        </div>
        <div className="row" id="map">
          <div className="col-12 p-0">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4816.1872279057125!2d29.038910835771443!3d41.00424235899992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab8061de0d0c3%3A0x1642fa01104c1cba!2zQWPEsWJhZGVtLCDEsGtsaW0gU29rYcSfxLEsIDM0NzE4IEthZMSxa8O2eS_EsHN0YW5idWw!5e0!3m2!1str!2str!4v1599118378042!5m2!1str!2str" width="100%" height="300" style={{border:0}} aria-hidden="false" title="Map"></iframe>
          </div>
        </div>
        <div className="row" id="footer">
          <div className="col-12">
            <div className="pt-4" id="social-icon">
              <img src={Facebook} alt="" className="icon pl-4"/>
              <img src={Twitter} alt="" className="icon"/>
              <img src={Linkedin} alt="" className="icon"/>
            </div>
            <div className="pt-4 pb-4">
              <p>Made with love by<span style={{fontSize:20}}><a href="https://www.freyjan.com/" target="_blank" style={{color: "#595959"}}> Freyjan.</a></span></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
