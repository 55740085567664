import axios from "axios";
import {create} from "mobx-persist";
import AppStore from "../../../Store";

const hydrate = create({
  storage: localStorage,
  jsonify: true
});
const appStore = new AppStore();

axios.interceptors.request.use(
  (config: any) => {
    const appStore = JSON.parse(localStorage.getItem("appStore") || "{}");
    if (appStore["token"]) {
      config.headers.Authorization = `Bearer ${appStore["token"]}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response.status === 401) {
      const store = await hydrate("appStore", appStore);
      await store.deleteToken();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default axios;