import {ContactRequest} from "../interfaces/requests/contact.request";
import httpClient from "./http/http.client";

const querystring = require("querystring");

export default class ContactService {

  public async sendMail(contactRequest: ContactRequest): Promise<any> {
    try {
      const response = await httpClient.post(
        `${process.env.REACT_APP_CONTACT_URL}/jade-herbal/send-email`,
        {...contactRequest}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async sendMailForm(contactRequest: ContactRequest): Promise<void> {
    try {
      const response = await httpClient.post(
        `${process.env.REACT_APP_CONTACT_URL}/jade-herbal/send-email`,
        querystring.stringify({
          name: contactRequest.name,
          surname: contactRequest.surname,
          subject: contactRequest.subject,
          message: contactRequest.message,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}