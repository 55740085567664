import {inject, observer} from "mobx-react";
import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import './App.scss';
import HomePage from "./pages/home/home";
import AppStore from "./Store";

@inject("appStore")
@observer
export default class App extends React.Component<{
  appStore?: AppStore;
}> {
  render() {
    return (
      <Switch>
        <Route exact path={HomePage.url} component={HomePage}/>
        <Route component={() => <Redirect to={HomePage.url}/>}/>
      </Switch>
    );
  }
}
